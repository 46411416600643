<template>
    <div class="playlist">
        <div class="buttons">
            <button type="button" @click="onPlayClicked($event)">Play!</button>
            <button type="button" @click="onPauseClicked($event)">Pause!</button>
            <button type="button" @click="onNextClicked($event)">Next!</button>
            <button type="button" @click="onPrevClicked($event)" disabled="disabled">Prev!</button>
            <button type="button" @click="onRestartClicked($event)">Restart!</button>
            <button type="button" @click="onZoomClicked($event)" disabled="disabled">Zoom!</button>
        </div>

        <div class="clips">
            <template v-for="clip in clips" :key="clip.id">
                <transition name="fade">
                    <VimeoClip
                        :ref="'player-' + clip.id"
                        :id="clip.id"
                        :start="clip.start"
                        :end="clip.end"
                        @clipStart="onClipStart"
                        @clipEnd="onClipEnd"
                        v-show="currentClipId == clip.id "
                        v-if="clip.type == 'vimeo'"/>
                </transition>
                <transition name="fade">
                    <YTClip
                        :ref="'player-' + clip.id"
                        :id="clip.id"
                        :start="clip.start"
                        :end="clip.end"
                        @clipStart="onClipStart"
                        @clipEnd="onClipEnd"
                        v-show="currentClipId == clip.id "
                        v-if="clip.type == 'youtube'"/>
                </transition>
            </template>
        </div>

    </div>
</template>

<script>
import VimeoClip from './VimeoClip.vue'
import YTClip from './YTClip.vue'

export default {
    name: 'Playlist',
    props: {
    },
    components: {
        VimeoClip,
        YTClip
    },
    data(){
        return {
            currentClipId: -1,
            clips: [
                {
                    id: '0a5gOO2Yfy8',
                    type: 'youtube',
                    start: 2,
                    end: 25
                },
                {
                    id: 'qFNfalgKBBE',
                    type: 'youtube',
                    start: 106,
                    end: 112
                },
                {
                    id: '-LorQ5BONXc',
                    type: 'youtube',
                    start: 34,
                    end: 41
                },
                {
                    id: '376131694',
                    type: 'vimeo',
                    start: 120,
                    end: 130
                },
                {
                    id: 'q2aDXPNVEMQ',
                    type: 'youtube',
                    start: 38,
                    end: 44
                },
                {
                    id: '1AnG04qnLqI',
                    type: 'youtube',
                    start: 30,
                    end: 37
                },
                {
                    id: '81997317',
                    type: 'vimeo',
                    start: 274,
                    end: 288
                }
            ]
        }
    },
    mounted() {
        this.currentClipId = this.clips[0].id;
    },
    methods: {
        onPlayClicked() {
            if (this.currentClipId != -1)
                this.playClip(this.currentClipId);
        },
        onPauseClicked() {
            if (this.currentClipId != -1)
                this.pauseClip(this.currentClipId);
        },
        onNextClicked() {
            let nextId = this.getNextClipId();
            if (nextId != -1) {
                this.playClip(nextId);
                if (this.currentClipId != -1)
                    this.resetClip(this.currentClipId);
            }
        },
        onPrevClicked(e) {
            console.log(e);
        },
        onRestartClicked() {
            this.currentClipId = this.clips[0].id;
            let clipIds = this.getClipIds();
            for (var i = 0; i < clipIds.length; i++) {
                this.$refs['player-' + clipIds[i]].reset();
            }
            this.playClip(this.currentClipId);
        },
        onZoomClicked(e) {
            console.log(e);
        },
        onClipStart: function(e) {
            this.currentClipId = e.id;
        },
        onClipEnd: function() {
            let nextId = this.getNextClipId();
            if (nextId != -1) {
                this.playClip(nextId);
            } else {
                let clipIds = this.getClipIds();
                this.currentClipId = clipIds[0];
            }

        },
        playClip: function(id) {
            this.$refs['player-' + id].play();
        },
        pauseClip: function(id) {
            this.$refs['player-' + id].pause();
        },
        resetClip: function(id) {
            this.$refs['player-' + id].reset();
        },
        getNextClipId() {
            let ids = this.getClipIds();
            let index = ids.indexOf(this.currentClipId);
            if (index >= ids.length - 1) return -1;
            else return ids[index + 1];
        },
        getClipById(id) {
            let tmp = this.clips.filter(clip => clip.id == id);
            if (tmp.length == 0) return null;
            return tmp[0];
        },
        getClipIds() {
            return this.clips.flatMap(clip => [clip.id]);
        }
    }
}
</script>
