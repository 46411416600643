<template>
    <div class="clip">
        <div :id="'player-' + id" class="player"></div>
    </div>
</template>

<script>
import YTPlayer from 'yt-player';

export default {
    name: 'YTClip',
    props: {
        id: String,
        start: Number,
        end: Number
    },
    data(){
        return {
            playing: false,
            player: null
        }
    },
    mounted() {
        var opts = {
            controls: false
        }
        var player = new YTPlayer('#player-' + this.id, opts);
        this.player = player;
        player.load(this.id, false, this.start);

        var that = this;
        player.on('playing', function() {
            if (!that.playing) {
                that.playing = true;
                that.$emit('clipStart', {id: that.id});
            }
        });
        player.on('timeupdate', function(seconds) {
            console.log(seconds);
            if (seconds > that.end) {
                if (that.playing) {
                    that.playing = false;
                    player.mute();
                    that.$emit('clipEnd', {id: that.id});
                    setTimeout(function() {
                        player.pause();
                        player.seek(that.start);
                        player.unMute();
                    }, 1000);
                }
            }
        });
    },
    methods: {
        play: function() {
            this.player.play();
            /*var tmp = document.getElementById('player-' + this.id);
            var player = new Player(tmp);
            player.setCurrentTime(this.start);
            player.play();
            */
        },
        pause: function() {
            this.player.pause();
            this.playing = false;
            /*var tmp = document.getElementById('player-' + this.id);
            var player = new Player(tmp);
            player.pause();
            this.playing = false;*/
        },
        reset: function() {
            this.pause();
            this.player.load(this.id, false, this.start);
            /*var tmp = document.getElementById('player-' + this.id);
            var player = new Player(tmp);
            player.pause();
            this.playing = false;
            player.setCurrentTime(this.start);*/
        }
    }
}
</script>
