<template>
    <div class="clip">
        <div :id="'player-' + id" class="player"></div>
    </div>
</template>

<script>
import Player from '@vimeo/player';

export default {
    name: 'VimeoClip',
    props: {
        id: String,
        start: Number,
        end: Number
    },
    data(){
        return {
            playing: false,
            player: null
        }
    },
    mounted() {
        var options = {
            width: 640,
            autopause: true,
            autoplay: false,
            controls: false,
            responsive: true,
            background: false
        }
        options.id = this.id;
        var player = new Player('player-' + this.id, options);

        var that = this;
        player.on('loaded', function() {
            player.setCurrentTime(that.start);
        });

        player.on('play', function() {
            that.playing = true;
            that.$emit('clipStart', {id: that.id});
        });
        player.on('timeupdate', function(e) {
            if (e.seconds > that.end) {
                if (that.playing) {
                    that.playing = false;
                    player.pause();
                    that.$emit('clipEnd', {id: that.id});
                    setTimeout(function() {
                        player.setCurrentTime(that.start);
                    }, 1000);
                }
            }
        });

        /*player.on('loaded', this.onClipLoaded);
        player.on('play', this.onClipPlay);*/

        //this.player = player;
    },
    methods: {
        play: function() {
            var tmp = document.getElementById('player-' + this.id);
            var player = new Player(tmp);
            player.setCurrentTime(this.start);
            player.play();
            this.playing = true;
        },
        pause: function() {
            var tmp = document.getElementById('player-' + this.id);
            var player = new Player(tmp);
            player.pause();
            this.playing = false;
        },
        reset: function() {
            var tmp = document.getElementById('player-' + this.id);
            var player = new Player(tmp);
            player.pause();
            this.playing = false;
            player.setCurrentTime(this.start);
        }
    }
}
</script>
